import { API_HOST } from "../config";
import { EndpointRoute } from "../enums";

const generateQueryString = (queries: any) => {
  let stringContent = "";
  for (const query in queries) {
    if (typeof queries[query] !== "object" || queries[query] instanceof Array) {
      stringContent += `${query}=${queries[query]}&`;
    }
  }
  const tidiedStringContent: string = stringContent.replace(/&$/, "");
  const queryString = `?${tidiedStringContent}`;
  return queryString;
};

export const generateUrl = (path: EndpointRoute): string => {
  return `${API_HOST}${path}`;
};

export const generateUrlWithQueryString = (path: EndpointRoute, queries: any): string => {
  return `${generateUrl(path)}${generateQueryString(queries)}`;
};

export const createHttpRequest = (body: any, method: "POST" | "PUT") => {
  return {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
};
