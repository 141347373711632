import { View } from "./enums";

export interface ViewPermission {
  read: boolean;
  write: boolean;
}

const revoked: ViewPermission = { read: false, write: false };

const readonly: ViewPermission = { read: true, write: false };

const fullAccess: ViewPermission = { read: true, write: true };

export interface Permission {
  [key: string]: ViewPermission;
}

const generatePermission = (viewPermission: ViewPermission): Permission => ({
  [View.Template]: viewPermission,
  [View.SchedulingRule]: viewPermission,
  [View.CancellationRule]: viewPermission,
  [View.ScheduledComm]: viewPermission,
  [View.Snippet]: viewPermission,
  [View.SentComm]: viewPermission,
});

const mergeViewPermission = (vP1: ViewPermission, vP2: ViewPermission): ViewPermission => ({
  read: vP1.read || vP2.read,
  write: vP1.write || vP2.write,
});

export const commsRoles: Record<string, Permission> = {
  commsAdmin: generatePermission(fullAccess),
  commsReadonly: generatePermission(readonly),
  default: generatePermission(revoked),
};

export const getPermission = (roles: string[]): Permission => {
  let permission: Permission = commsRoles.default;

  if (roles) {
    commsRolesOnly(roles).map(r => (permission = mergePermission(permission, commsRoles[r])));
  }

  return permission;
};

const mergePermission = (currentPermission: Permission, newPermission: Permission): Permission => {
  const keys = Object.keys(currentPermission);
  keys.forEach(k => {
    currentPermission[k] = mergeViewPermission(currentPermission[k], newPermission[k]);
  });
  return currentPermission;
};

const commsRolesOnly = (roles: string[]): string[] => roles.filter(r => commsRoles[r] !== undefined);
