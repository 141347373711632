export const AAD_ACQUIRED_ID_TOKEN_SUCCESS = "AAD_ACQUIRED_ID_TOKEN_SUCCESS";
export const AAD_INITIALIZED = "AAD_INITIALIZED";
export const LOGIN_SUCCESS = "APP/LOGIN_SUCCESS";

export const GET_AUGMENTERS = "APP/GET_AUGMENTERS";
export const GET_AUGMENTERS_SUCCESS = "APP/GET_AUGMENTERS_SUCCESS";
export const GET_AUGMENTERS_FAIL = "APP/GET_AUGMENTERS_FAIL";

export const GET_TRIGGERS = "APP/GET_TRIGGERS";
export const GET_TRIGGERS_SUCCESS = "APP/GET_TRIGGERS_SUCCESS";
export const GET_TRIGGERS_FAIL = "APP/GET_TRIGGERS_FAIL";

export const UPLOAD_IMAGE = "APP/UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "APP/UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "APP/UPLOAD_IMAGE_FAIL";

export const GET_TENANTS = "APP/GET_TENANTS";
export const GET_TENANTS_SUCCESS = "APP/GET_TENANTS_SUCCESS";
export const GET_TENANTS_FAIL = "APP/GET_TENANTS_FAIL";
export const SET_TENANT = "APP/SET_TENANT";