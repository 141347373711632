import { Reducer } from "redux";

export interface LoaderState {
  requests: { [id: string]: boolean };
  loading: boolean;
  progress: number;
}

const initialState = {
  requests: {},
  loading: false,
  progress: 0
};

export const LoaderReducer: Reducer<LoaderState> = (state = initialState, action: any) => {
  const { type } = action;

  switch (action.type) {
    default:
      const matches = /((GET|SAVE|UPDATE )_.*)(_(SUCCESS|FAIL))?/.exec(type);
      if (!matches) return state;

      const [requestName] = matches;
      if (!requestName) return state;

      const orginalAction = requestName.replace("_SUCCESS", "").replace("_FAIL", "");

      const requests = state.requests;
      let loading = false;
      requests[orginalAction] = requestName === orginalAction;

      for (const request in requests) {
        loading = loading || requests[request];
      }

      return {
        ...state,
        requests,
        loading
      };
  }
};

export default LoaderReducer;
