/* eslint-disable @typescript-eslint/camelcase */
import { Rule, Combinator } from "./types";
import { Guid } from "guid-typescript";
import {
  Add_RULE,
  REMOVE_RULE,
  UPDATE_RULE,
  INIT_RULES,
  INIT_FIELDS,
  QueryBuilderActionTypes,
  UPDATE_COMBINATOR,
} from "./actionTypes";
import { FieldType, CombinatorType } from "../enums";
import { Field } from "..";

export interface QueryBuilderState {
  rules: Rule[];
  fields: { [name: string]: Field };
  extraFields: { [name: string]: Field };
  combinator: Combinator;
}

const getEmptyRule = (): Rule => ({
  id: Guid.raw(),
  field: { type: FieldType.None, name: "" },
  operatorName: "",
  values: [],
  requiredValues: 0,
});

const initialState: QueryBuilderState = {
  rules: [],
  fields: {},
  extraFields: {},
  combinator: {
    value: CombinatorType.And,
    data: Object.values(CombinatorType),
    canDeselect: false,
    exclusive: true,
  },
};

export function QueryBuilderReducer(
  state = initialState,
  action: QueryBuilderActionTypes
): QueryBuilderState {
  switch (action.type) {
    case UPDATE_COMBINATOR:
      return {
        ...state,
        combinator: { ...state.combinator, value: action.value },
      };
    case Add_RULE:
      return {
        ...state,
        rules: [...state.rules, getEmptyRule()],
      };
    case REMOVE_RULE:
      return {
        ...state,
        rules: state.rules.filter((r: Rule) => r.id !== action.rule.id),
      };
    case UPDATE_RULE:
      const newRules = state.rules.slice(0);
      const index = newRules.findIndex((r: Rule) => r.id === action.rule.id);
      newRules[index] = action.rule;
      return { ...state, rules: newRules };
    case INIT_RULES:
      return {
        ...state,
        rules: action.rules,
      };
    case INIT_FIELDS:
      return {
        ...state,
        rules: [],
        fields: action.fields,
        extraFields: action.extraFields,
      };
    default:
      return state;
  }
}
