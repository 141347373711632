/* eslint-disable @typescript-eslint/camelcase */
import { Rule, Field } from "./types";
import { CombinatorType } from "../enums";

export const Add_RULE = "RULE_CONTAINER/ADD_RULE";
export const REMOVE_RULE = "RULE_CONTAINER/REMOVE_RULE";
export const UPDATE_RULE = "RULE_CONTAINER/UPDATE_RULE";
export const INIT_RULES = "RULE_CONTAINER/INIT_RULES";
export const INIT_FIELDS = "RULE_CONTAINER/INIT_FIELDS";
export const UPDATE_COMBINATOR = "COMBINATOR/UPDATE_COMBINATOR";

export interface AddRuleAction {
  type: typeof Add_RULE;
}

export interface RemoveRuleAction {
  type: typeof REMOVE_RULE;
  rule: Rule;
}

export interface UpdateRuleAction {
  type: typeof UPDATE_RULE;
  rule: Rule;
}

export interface InitRulesAction {
  type: typeof INIT_RULES;
  rules: Rule[];
}

export interface InitFieldsAction {
  type: typeof INIT_FIELDS;
  fields: {
    [name: string]: Field;
  };
  extraFields: {
    [name: string]: Field;
  };
}

interface UpdateCombinatorAction {
  type: typeof UPDATE_COMBINATOR;
  value: CombinatorType;
}

export type QueryBuilderActionTypes =
  | RemoveRuleAction
  | AddRuleAction
  | UpdateRuleAction
  | InitRulesAction
  | InitFieldsAction
  | UpdateCombinatorAction;
