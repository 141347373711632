import { DataType, SortOrder, SmartRange, FieldType, AugmenterOwner } from "../../../enums";
import { CombinatorType } from "@comms/react-query-builder";
import { Permission } from "../../../role";
import { Rule } from "@comms/react-query-builder/src/store/types";

export interface Column {
  name: string;
  label?: string;
  dataType: DataType;
  sortOrder?: SortOrder;
  disableSort?: boolean;
}

export interface Dialog {
  open: boolean;
}

export interface DateRange {
  startDate?: Date;
  endDate?: Date;
  smartRange?: SmartRange;
}

export enum MUIColumnNames {
  "name" = "name",
  "triggerName" = "triggerName",
  "tenantName" = "tenantName",
  "lastModified" = "lastModified",
  "templateName" = "templateName",
  "active" = "active",
  "createdOn" = "createdOn",
  "sendOn" = "sendOn",
  "sentOn" = "sentOn",
  "subject" = "subject",
  "recipient" = "recipient",
  "sent" = "sent",
}

export interface Dictionary<TValue> {
  [key: string]: TValue;
}

export interface TableRequest {
  sortAscending?: boolean;
  pageRequested: number;
  itemsPerPage: number;
}

export interface UpdateTableHeadersObject {
  columnIndex: number;
  sortOrder: SortOrder;
}

export interface Status {
  id: string;
  name: string;
}

export interface ScheduledDateType {
  id: number;
  name: string;
}

export interface Account {
  userName: string;
  exp: number;
  idToken: string;
  roles: string[];
  permission: Permission;
}

export interface Augmenter {
  id: string;
  description: string;
  requiredInputs: AugmenterInput[];
  outputFields: Field[];
}

export interface AugmenterInput {
  path: string;
  displayName: string;
  description: string;
  isArray: false;
  type: FieldType;
}

export interface AugmenterInputMappings {
  [augmenterField: string]: string;
}

export interface AugmenterMappings {
  augmenterId: string;
  alias: string;
  augmenterOwner: AugmenterOwner;
  mappings: AugmenterInputMappings;
}

export interface Field {
  path: string;
  field: string;
  displayName: string;
  description: string;
  type: FieldType;
  isArray: boolean;
  isTemplateField: boolean;
  isRecipientSource: boolean;
}

export interface Trigger {
  id: string;
  name: string;
}

export interface TriggerDetails extends Trigger {
  active: boolean;
  fields: Field[];
  augmenterMappings: AugmenterMappings[];
}

export interface TriggerDetailsPayload extends Trigger {
  disabled: boolean;
  fields: Field[];
  augmenterMappings: Dictionary<AugmenterInputMappings>;
}

export interface Condition {
  rawState: { rules: Rule[]; combinator: CombinatorType };
  logic: any;
}

export const UndefinedMapping = "undefined";

export interface PromiseResolver {
  resolve: (data: any) => void;
  reject: (data: any) => void;
}

export interface EditorImage {
  name: string;
  imageData: any;
  promiseResolver?: PromiseResolver;
}

export interface Tenant {
  id: string;
  name: string;
}

export enum TenantStatus {
  LOADING = "loading",
  SUCCESS = "success",
  FAILED = "failed"
}